.ant-popover-content {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.32);
}

.interview_wrappper {
  & .ant-tabs-ink-bar {
    position: absolute;
    pointer-events: none;
    background: #45a5b0 !important;
  }
}
