.footer_container {
  background-color: rgb(255, 255, 255);
  z-index: 900;
  position: sticky;
  bottom: 0;
}

.header_container {
  background-color: rgb(255, 255, 255);
  // z-index: 900;
}

.primary_border_bottom {
  border-bottom: 4px solid #246a7b;
}

.anticon-home svg {
  margin-top: -8px !important;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px 0 !important;
}

.authorization_modal .ant-modal-body {
  text-align: center;
}

.authorization_modal .ant-modal-content {
  border-radius: 6px !important;
  overflow: hidden;
}
