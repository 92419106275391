@tailwind components;
@tailwind utilities;
@tailwind base;

@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
    filter: blur(0);
  }
}

* {
  font-family: Nunito, sans-serif;
}

.container {
  max-width: 90%;
}

#myVideo {
  background-color: #246a7b;
}

.card-container p {
  margin: 0;
}

.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -8px;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}

[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme="dark"]
  .card-container
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

.vjs-record-button {
  display: none !important;
}

.text_primary {
  color: #246a7b !important;
}

.button_primary {
  background-color: #246a7b !important;
  border-color: #246a7b !important;
}

.anticon-plus svg {
  margin-top: -8px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  background-color: #12727d;
  border-color: #12727d;
}

body {
  font-size: 18px;
}

.tilt-in-fwd-tr {
  -webkit-animation: tilt-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: tilt-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.slide-in-elliptic-top-fwd {
  -webkit-animation: slide-in-elliptic-top-fwd 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-top-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.ant-table-tbody > tr > td {
  border-color: rgb(229, 231, 235) !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background-color: white !important;
  border: none !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: white !important;
  border: none;
  border-bottom: 2px solid #178f9d !important;
  color: #178f9d !important;
  border-spacing: 15px;
}

.ant-tabs-tab-btn {
  &:hover {
    color: black !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  :hover {
    color: rgb(23, 143, 157) !important;
  }
  color: rgb(23, 143, 157) !important;
}

.c-btn-primary {
  padding: 6px 10px;
  border-radius: 8px;
  background-color: #ccfbf1;
  color: #115e59;
  font-size: 115%;
}

.c-btn-reset {
  border: 3px solid #b8c2ce;
}

.ant-pagination-item-active {
  border: 1px solid #21bcab !important;
}

.ant-pagination-item-active a {
  color: #21bcab !important;
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 10000px;
  height: 30px;
  width: 30px;
  margin: 0 4px;
  border: 1px solid black;
  transition: all 1s;

  &:hover {
    background-color: black;
    color: white;
  }
}

.hover-green {
  border-color: #21bcab;
  color: #21bcab;

  &:hover {
    background-color: #21bcab;
    color: white;
  }
}

.hover-red {
  border-color: #ff4d4d;
  color: #ff4d4d;

  &:hover {
    background-color: #ff4d4d;
    color: white;
  }
}

.hover-blue {
  border-color: #1945a4;
  color: #1945a4;

  &:hover {
    background-color: #1945a4;
    color: white;
  }
}

.hover-orange {
  border-color: #ff8c00;
  color: #ff8c00;

  &:hover {
    background-color: #ff8c00;
    color: white;
  }
}

.hover-purple {
  border-color: #7e51b5;
  color: #7e51b5;

  &:hover {
    background-color: #7e51b5;
    color: white;
  }
}

button[disabled] {
  opacity: 0.7;

  & :hover {
    cursor: not-allowed !important;
  }
}

button {
  transition: all 0.4s;

  &:hover {
    filter: brightness(120%) !important;
  }
}

.icon-stack {
  position: absolute;
}

.previewLayout {
  height: 500px;
  max-height: 500px;
}

//media queries for medium & plus
@media (min-width: 768px) {
  .previewLayout {
    height: 40vw;
    max-height: 40vw;
  }
}

#preview {
  // reset to original html style
  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li {
    list-style-position: inside;
  }
}

.list-disc {
  ul {
    list-style: disc !important;
  }
}
