.c_input {
  padding: 8px 12px !important;
  border-radius: 16px;
  font-size: 18px;

  &::placeholder {
    color: #757779;
  }

  &:focus {
    border: 1px solid #0e5256 !important;
    box-shadow: 0px 0px 2px #0e5256;
  }
}

.ant-form-item-label > label {
  font-size: 16px;
}

.c_label {
  font-size: 18px !important;
}

.c_input-2 {
  padding: 5px 9px !important;
  border-radius: 9px !important;

  &::placeholder {
    color: #757779;
  }

  &:focus {
    border: 1px solid #0e5256 !important;
    box-shadow: 0px 0px 2px #0e5256;
  }
}

.ant-input[disabled] {
  color: black;
}

#interview {
  & .ant-pagination-item {
    border: 1px solid #0e5256;
    border-radius: 1000px;
    height: 36px;
    width: 36px;
    padding: 5px 0;
    font-size: 16px;
    font-weight: 500;
    margin: 0 4px;

    & a {
      color: #0e5256 !important;
      cursor: not-allowed;
    }

    &-active {
      background-color: #0e5256;
      color: white !important;

      & a {
        color: white !important;
      }
    }
  }

  & .ant-pagination-next,
  .ant-pagination-prev {
    display: none;
  }
}

.app .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
#textEditor .ql-bubble .ql-editor {
}

#textEditor .ql-editor {
  min-height: 24em;
}

#textEditor .quill {
  border: 1px solid #ccc;
  border-radius: 0.5em;
  overflow: hidden;
}

#textEditor .ql-toolbar {
  border-top: none;
  background: #fefcfc;
  text-align: center;
}

.questionTextEditor .ql-bubble .ql-editor {
}

.questionTextEditor .ql-editor {
  min-height: 6em;
}

.questionTextEditor .quill {
  border: 1px solid #ccc;
  border-radius: 0.5em;
  overflow: hidden;
}

.questionTextEditor .ql-toolbar {
  border-top: none;
  background: #fefcfc;
  text-align: center;

  .ql-link {
    display: none;
  }
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

.text-green-500 {
  color: #7fff00;
}

.custom-markdown {
  /* Reset margin and padding for all elements */
  * {
    margin: 0;
    padding: 0;
  }

  /* Headings */
  h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  /* Paragraphs */
  p {
    margin-bottom: 1em;
    line-height: 1.5;
  }

  /* Lists */
  ul,
  ol {
    margin-bottom: 1em;
    padding-left: 1.5em;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    margin-bottom: 0.5em;
  }

  /* Links */
  a {
    color: #007bff;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  /* Emphasis */
  em {
    font-style: italic;
  }

  /* Strong */
  strong {
    font-weight: bold;
  }

  /* Code */
  code {
    font-family: monospace;
    background-color: #f1f1f1;
    padding: 0.15em 0.3em;
    border-radius: 3px;
  }

  /* Blockquote */
  blockquote {
    margin: 0;
    padding-left: 1em;
    border-left: 4px solid #ccc;
    color: #777;
  }

  /* Horizontal Rule */
  hr {
    margin: 1.5em 0;
    border: 0;
    border-top: 1px solid #ddd;
  }

  /* Preformatted Text */
  pre {
    font-family: monospace;
    background-color: #f7f7f7;
    padding: 1em;
    border-radius: 3px;
    overflow: auto;
  }

  /* Inline Code */
  pre code {
    display: inline;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  /* Image */
  img {
    max-width: 100%;
    height: auto;
  }
}
